import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall, logoutUser } from '../../../API';
import * as Toast from "../../../components/shared/Toast";

export const getallServersACTION = createAsyncThunk(
  'configuration/getallservers',
  async (data) => {
    return apiCall('/manage/configuration/getallservers', 'POST', data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });

  }
);