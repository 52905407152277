import { combineReducers } from 'redux'
import authReducer from './authReducer'
import customerReducer from './customers/customerReducer';
import roleReducer from './roles/roleReducer';
import userAdminReducer from './userAdmin/userAdminReducer';
import distributorReducer from './distributors/distributerReducer'
import configurationReducer from './/configuration/configurationReducer'
const reducer = {
    auth: authReducer,
    customerReducer,
    roleReducer,
    userAdminReducer,
    distributorReducer,
    configurationReducer
}
const appReducer = combineReducers(reducer);


const rootReducer = (state, action) => {
    if (action.type === 'auth/logout/fulfilled') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}


export default rootReducer;
// export default combineReducers(reducer);

export function CLEARSTORE() {
    return {
        type: "CLEARSTORE"
    };
}
