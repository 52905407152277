import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { getallCustomersACTION, getCustomerACTION, insertUpdateCustomerAction, customerChangeStatusACTION, customerDeleteACTION, getallRestuarantACTION, getRestuarantACTION, getDatabaseACTION, insertUpdateRestuarantAction, restuaranatChangeStatusACTION, restuarantDeleteACTION, genetareSyncCodeACTION, customerAutoLoginAction, getRestaurantCommentACTION, insertUpdateRestuarantCommentAction, getCustomerCredentials } from "../../actions/customers/customerAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    customers: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedCustomer: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    restuarant: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedRestuarant: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedDatabase: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateRestuarantDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    activeInactiveRestuarantDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteRestuarantDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedSyncCode: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    autoLoginDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedRestaurantComment: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateRestuarantCommentDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedCustomerCredentials: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const customerReducer = createSlice({
    name: "customer",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetCustomerDetailsState(state, action) {
            state.selectedCustomer = INITIAL_STATE.selectedCustomer;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.selectedRestuarant = INITIAL_STATE.selectedRestuarant;
            state.insertUpdateRestuarantDetails = INITIAL_STATE.insertUpdateRestuarantDetails;
            state.selectedRestaurantComment = INITIAL_STATE.selectedRestaurantComment;
            state.insertUpdateRestuarantCommentDetails = INITIAL_STATE.insertUpdateRestuarantCommentDetails;
        },
        ResetAutoLoginDetails(state, action) {
            state.autoLoginDetails = INITIAL_STATE.autoLoginDetails;
        }
    },
    extraReducers: (builder) => {

        // getallCustomersACTION start

        builder.addCase(getallCustomersACTION.fulfilled, (state, action) => {
            state.customers.data = action.payload.data;
            state.customers.apiMsg.status = action.meta.requestStatus;
            state.customers.apiMsg.message = "success";
        });
        builder.addCase(getallCustomersACTION.pending, (state, action) => {
            state.customers.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getallCustomersACTION.rejected, (state, action) => {
            state.customers.apiMsg.message = action.error.message;
            state.customers.apiMsg.status = action.meta.requestStatus;
        });


        // getallCustomersACTION end
        //------------------------------- getCustomerACTION start -------------------------

        builder.addCase(getCustomerACTION.fulfilled, (state, action) => {
            state.selectedCustomer.data = action.payload.data;
            state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
            state.selectedCustomer.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getCustomerACTION.pending, (state, action) => {
            state.selectedCustomer.data = null;
            state.selectedCustomer.apiMsg.message = action.meta.requestStatus;;
            state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getCustomerACTION.rejected, (state, action) => {
            state.selectedCustomer.apiMsg.message = action.error.message;
            state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
        });


        //   getCustomerACTION end

        // insertUpdateCustomerAction start

        builder.addCase(
            insertUpdateCustomerAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.customers.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.customers.data.records = updatedRecord;
                }
                else {
                    if (state.customers.data.records.length) {
                        state.customers.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.customers.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateCustomerAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateCustomerAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateCustomerAction end

        // customerChangeStatusACTION start


        builder.addCase(customerChangeStatusACTION.fulfilled, (state, action) => {
            state.customers.data.records = state.customers.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(customerChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(customerChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // customerChangeStatusACTION end



        //------------------------------- customerDeleteACTION start -------------------------

        builder.addCase(customerDeleteACTION.fulfilled, (state, action) => {

            state.customers.data.records = state.customers.data.records.filter((item, index) => item.id !== action.payload.data.id)

            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(customerDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(customerDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- customerDeleteACTION end -------------------------

        // getallRestuarantACTION start

        builder.addCase(getallRestuarantACTION.fulfilled, (state, action) => {
            state.restuarant.data = action.payload.data;
            state.restuarant.apiMsg.status = action.meta.requestStatus;
            state.restuarant.apiMsg.message = "success";
        });
        builder.addCase(getallRestuarantACTION.pending, (state, action) => {
            state.restuarant.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getallRestuarantACTION.rejected, (state, action) => {
            state.restuarant.apiMsg.message = action.error.message;
            state.restuarant.apiMsg.status = action.meta.requestStatus;
        });

        // getallRestuarantACTION end

        //------------------------------- getRestuarantACTION start -------------------------

        builder.addCase(getRestuarantACTION.fulfilled, (state, action) => {
            state.selectedRestuarant.data = action.payload.data;
            state.selectedRestuarant.apiMsg.status = action.meta.requestStatus;
            state.selectedRestuarant.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getRestuarantACTION.pending, (state, action) => {
            state.selectedRestuarant.data = null;
            state.selectedRestuarant.apiMsg.message = action.meta.requestStatus;;
            state.selectedRestuarant.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getRestuarantACTION.rejected, (state, action) => {
            state.selectedRestuarant.apiMsg.message = action.error.message;
            state.selectedRestuarant.apiMsg.status = action.meta.requestStatus;
        });
        //   getRestuarantACTION end

        //------------------------------- getDatabaseACTION start -------------------------

        builder.addCase(getDatabaseACTION.fulfilled, (state, action) => {
            state.selectedDatabase.data.records = action.payload.data;
            state.selectedDatabase.apiMsg.status = action.meta.requestStatus;
            state.selectedDatabase.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getDatabaseACTION.pending, (state, action) => {
            state.selectedDatabase.data.records = null;
            state.selectedDatabase.apiMsg.message = action.meta.requestStatus;;
            state.selectedDatabase.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getDatabaseACTION.rejected, (state, action) => {
            state.selectedDatabase.apiMsg.message = action.error.message;
            state.selectedDatabase.apiMsg.status = action.meta.requestStatus;
        });


        //   getDatabaseACTION end

        // insertUpdateRestuarantAction start

        builder.addCase(
            insertUpdateRestuarantAction.fulfilled,
            (state, action) => {
                console.log("action.payload.data.data", action.payload.data.data);
                console.log("action.meta.arg", action.meta.arg);

                state.insertUpdateRestuarantDetails.data = action.payload.data.data;
                state.insertUpdateRestuarantDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateRestuarantDetails.apiMsg.message = action.payload.message.message;

                const { customerFirstName, customerLastName, distributorFirstName, distributorLastName } = action.meta.arg;

                if (!action.payload.newRecord) {
                    // Update existing record and replace names based on meta.arg
                    let updatedRecord = state.restuarant.data.records.map((x) => {
                        if (x.id === action.payload.data.data.id) {
                            return {
                                ...action.payload.data.data,
                                customerFirstName,
                                customerLastName,
                                distributorFirstName,
                                distributorLastName,
                            };
                        } else {
                            return x;
                        }
                    });

                    state.restuarant.data.records = updatedRecord;
                } else {
                    // Add a new record with updated names from meta.arg
                    const newRecord = {
                        ...action.payload.data.data,
                        customerFirstName,
                        customerLastName,
                        distributorFirstName,
                        distributorLastName,
                    };

                    if (state.restuarant.data.records.length) {
                        state.restuarant.data.records.unshift(newRecord);
                    } else {
                        state.restuarant.data.records.push(newRecord);
                    }
                }

                // Toast notification
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error",
                });
            }
        );

        builder.addCase(
            insertUpdateRestuarantAction.pending,
            (state, action) => {
                state.insertUpdateRestuarantDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateRestuarantAction.rejected,
            (state, action) => {

                state.insertUpdateRestuarantDetails.apiMsg.message = action.error.message;
                state.insertUpdateRestuarantDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateRestuarantAction end

        // restuaranatChangeStatusACTION start


        builder.addCase(restuaranatChangeStatusACTION.fulfilled, (state, action) => {
            state.restuarant.data.records = state.restuarant.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restuaranatChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restuaranatChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // restuaranatChangeStatusACTION end



        //------------------------------- restuarantDeleteACTION start -------------------------

        builder.addCase(restuarantDeleteACTION.fulfilled, (state, action) => {

            state.restuarant.data.records = state.restuarant.data.records.filter((item, index) => item.id !== action.payload.data.id)
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restuarantDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restuarantDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- restuarantDeleteACTION end -------------------------

        //------------------------------- genetareSyncCodeACTION start -------------------------

        builder.addCase(genetareSyncCodeACTION.fulfilled, (state, action) => {
            state.selectedSyncCode.data = action.payload.data;
            state.selectedSyncCode.apiMsg.status = action.meta.requestStatus;
            state.selectedSyncCode.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(genetareSyncCodeACTION.pending, (state, action) => {
            state.selectedSyncCode.data = null;
            state.selectedSyncCode.apiMsg.message = action.meta.requestStatus;;
            state.selectedSyncCode.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(genetareSyncCodeACTION.rejected, (state, action) => {
            state.selectedSyncCode.apiMsg.message = action.error.message;
            state.selectedSyncCode.apiMsg.status = action.meta.requestStatus;
        });

        //   genetareSyncCodeACTION end

        //   getAutoLoginDetails start

        builder.addCase(customerAutoLoginAction.fulfilled, (state, action) => {
            state.autoLoginDetails.data = action.payload.data;
            state.autoLoginDetails.apiMsg.status = action.meta.requestStatus;
            state.autoLoginDetails.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(customerAutoLoginAction.pending, (state, action) => {
            state.autoLoginDetails.data = null;
            state.autoLoginDetails.apiMsg.message = action.meta.requestStatus;;
            state.autoLoginDetails.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(customerAutoLoginAction.rejected, (state, action) => {
            state.autoLoginDetails.apiMsg.message = action.error.message;
            state.autoLoginDetails.apiMsg.status = action.meta.requestStatus;
        });

        //   getAutoLoginDetails end


        builder.addCase(getRestaurantCommentACTION.fulfilled, (state, action) => {
            state.selectedRestaurantComment.data = action.payload.data;
            state.selectedRestaurantComment.apiMsg.status = action.meta.requestStatus;
            state.selectedRestaurantComment.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getRestaurantCommentACTION.pending, (state, action) => {
            state.selectedRestaurantComment.data = null;
            state.selectedRestaurantComment.apiMsg.message = action.meta.requestStatus;;
            state.selectedRestaurantComment.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getRestaurantCommentACTION.rejected, (state, action) => {
            state.selectedRestaurantComment.apiMsg.message = action.error.message;
            state.selectedRestaurantComment.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(
            insertUpdateRestuarantCommentAction.fulfilled,
            (state, action) => {
                state.insertUpdateRestuarantCommentDetails.data = action.payload.data.data;
                state.insertUpdateRestuarantCommentDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateRestuarantCommentDetails.apiMsg.message = action.payload.message.message;
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateRestuarantCommentAction.pending,
            (state, action) => {
                state.insertUpdateRestuarantCommentDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateRestuarantCommentAction.rejected,
            (state, action) => {

                state.insertUpdateRestuarantCommentDetails.apiMsg.message = action.error.message;
                state.insertUpdateRestuarantCommentDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        
        builder.addCase(getCustomerCredentials.fulfilled, (state, action) => {
            state.selectedCustomerCredentials.data = action.payload.data;
            state.selectedCustomerCredentials.apiMsg.status = action.meta.requestStatus;
            state.selectedCustomerCredentials.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getCustomerCredentials.pending, (state, action) => {
            state.selectedCustomerCredentials.data = null;
            state.selectedCustomerCredentials.apiMsg.message = action.meta.requestStatus;;
            state.selectedCustomerCredentials.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getCustomerCredentials.rejected, (state, action) => {
            state.selectedCustomerCredentials.apiMsg.message = action.error.message;
            state.selectedCustomerCredentials.apiMsg.status = action.meta.requestStatus;
        });

    },
});

export const { RESET, ResetCustomerDetailsState, ResetAutoLoginDetails } = customerReducer.actions;
export default customerReducer.reducer;