import { createSlice } from "@reduxjs/toolkit";
import { getallServersACTION } from "../../actions/configuration/configurationAction";


const INITIAL_STATE = {
    Servers: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }
};
const configurationReducer = createSlice({
    name: "configuration",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getallServersACTION.fulfilled, (state, action) => {
            state.Servers.data = action.payload.data;
            state.Servers.apiMsg.status = action.meta.requestStatus;
            state.Servers.apiMsg.message = "success";
        });
        builder.addCase(getallServersACTION.pending, (state, action) => {
            state.Servers.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getallServersACTION.rejected, (state, action) => {
            state.Servers.apiMsg.message = action.error.message;
            state.Servers.apiMsg.status = action.meta.requestStatus;
        });
    },
});

export const { RESET } = configurationReducer.actions;
export default configurationReducer.reducer;